body {
  margin: 0;
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: $color-black; }

* {
  box-sizing: border-box;

  &:before,
  &:after {
    box-sizing: border-box; } }
