.Form {
  padding: 60px 0;

  &-top {
    margin-bottom: 10px; }

  &-success {
    margin-top: 15px;
    text-align: center;
    color: $color-ternary;
    display: none; }

  .error {
    color: #ff0000;
    width: 100%; } }

@media(min-width: $screen-sm-min) {
  .Form {

    &-top {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }

    &-item {
      width: calc(33.33% - 35px); } } }
