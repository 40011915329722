.Footer {

  &-top {
    padding: 30px 0;
    background-color: #252525;
    color: $color-white; }

  &-bottom {
    padding: 20px 0;
    background-color: #111111;
    color: #898989;
    font-size: 14px; }

  &-nav {
    width: 100%;
    text-align: center;

    &:first-child {
      margin-bottom: 40px; } }

  &-navLink {
    color: $color-white;
    text-decoration: none;
    width: 100%;
    display: inline-block;
    margin-bottom: 15px;

    &:hover {
      text-decoration: underline; }

    &:last-child {
      margin-right: 0;
      margin-bottom: 0; } }

  &-lang {
    width: 100%;
    text-align: center;
    margin-bottom: 25px; }

  &-langLink {
    display: inline-block;
    height: 10px;
    transition: $transition;
    margin-right: 10px;

    img {
      display: block; }

    &:hover {
      opacity: .6; }

    &:last-child {
      margin-right: 0; } }

  &-copy {
    width: 100%; }

  &-created {
    width: 100%;
    margin-top: 25px;
    text-align: center; }

  &-createdLink {
    color: #898989;

    &:hover {
      text-decoration: none; } } }

@media(min-width: $screen-md-min) {
  .Footer {

    &-nav {
      width: calc(50% - 15px);
      text-align: right;

      &:first-child {
        margin-bottom: 0;
        text-align: left; } }

    &-navLink {
      margin-right: 35px;
      margin-bottom: 0;
      width: auto; }

    &-lang {
      width: calc(25% - 15px);
      text-align: left;
      margin-bottom: 0; }

    &-langLink {
      margin-right: 0; }

    &-copy {
      width: calc(50% - 30px); }

    &-created {
      text-align: right;
      width: calc(25% - 15px);
      margin-top: 0; } } }
