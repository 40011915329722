.Headline {
  font-family: "Krub", sans-serif;
  margin: 0;

  &--main {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.3; }

  &--white {
    color: $color-white; }

  &--section {
    color: $color-secondary;
    text-align: center;
    font-size: 30px;
    margin-bottom: 25px; }

  &--sectionSecondary {
    color: $color-white;
    font-size: 30px;
    margin-bottom: 15px; }

  &--state {
    color: $color-secondary;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 26px; }

  &--noSpacing {
    margin: 0; } }

@media(min-width: $screen-sm-min) {
  .Headline {

    &--main {
      font-size: 40px; } } }

@media(min-width: $screen-lg-min) {
  .Headline {

    &--main {
      font-size: 55px; } } }
