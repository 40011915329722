.Container {
  max-width: 1140px;
  margin: auto;
  padding: 0 20px;

  &--flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }

  &--tight {
    max-width: 820px; } }

@media(min-width: $screen-sm-min) {
  .Container {
    padding: 0 30px; } }
