$color-black: #000;
$color-white: #fff;
$color-primary: #f39f01;
$color-secondary: #326a2f;
$color-ternary: #95c105;

$screen-xs-max: 575px;
$screen-sm-min: 576px;
$screen-sm-max: 767px;
$screen-md-min: 768px;
$screen-md-max: 991px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;

$transition: all ease-in-out .3s;
