.Text {

  &--sm {
    font-size: 16px; }

  &--md {
    font-size: 18px; }

  &--semiBold {
    font-weight: 500; } }
