.Input {
  width: 100%;
  border: none;
  background-color: #f6f6f6;
  padding: 15px 20px;
  font-size: 16px;
  resize: none;
  margin-bottom: 10px;

  &:focus {
    outline: none; }

  &-label {
    display: block;
    padding-left: 20px; }

  &-required {
    color: $color-primary; }

  &--textarea {
    margin-bottom: 20px; } }

@media(min-width: $screen-sm-min) {
  .Input {
    margin-bottom: 0;

    &--textarea {
      margin-bottom: 20px; } } }
