.Hero {
  padding: 120px 0;
  background-image: url('/images/hero.jpg');
  background-size: cover;
  background-position: center;
  position: relative;

  &--about {
    background-image: url('/images/o-nas.jpg'); }

  &--sortiment {
    background-image: url('/images/sortiment.jpg'); }

  &--contact {
    background-image: url('/images/kontakt.jpg'); } }

@media(min-width: $screen-sm-min) {
  .Hero {
    padding: 160px 0;

    &--about,
    &--sortiment,
    &--contact {
      padding: 115px 0; } } }
