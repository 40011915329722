.Navbar {
  position: fixed;
  width: 100%;
  padding: 20px 0;
  z-index: 1000;
  background-color: $color-white;

  &-indentation {
    height: 72px; }

  &-logo {
    display: inline-block;
    height: 32px;
    width: 171px;

    svg {
      max-height: 100%; } }

  &-menu {
    position: absolute;
    width: 100%;
    height: calc(100vh - 72px);
    top: 72px;
    left: 100%;
    background-color: $color-white;
    padding-top: 10px;
    transition: left ease .3s; }

  &-link {
    color: $color-black;
    text-decoration: none;
    transition: $transition;
    display: block;
    text-align: center;
    padding: 20px 0;

    &:hover {
      color: $color-primary; }

    &--border {
      margin: 20px auto 0;
      padding: 10px 45px;
      border: 2px solid $color-primary;
      border-radius: 23px;
      max-width: 155px;

      &:hover {
        background-color: $color-primary;
        color: $color-white; }

      &.Navbar-link--isActive {
        color: $color-white;
        background-color: $color-primary;

        &:hover {
          color: $color-primary;
          background-color: $color-white; } } }

    &--isActive {
      color: $color-primary; } }

  &-lang {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px; }

  &-langLink {
    display: block;
    height: 10px;
    margin-bottom: 5px;
    transition: $transition;

    img {
      display: block; }

    &:hover {
      opacity: .6; } }

  &-langMob {
    text-align: center;
    margin-top: 35px;

    .Navbar-langLink {
      display: inline-block;
      margin-bottom: 0;
      margin-right: 10px;

      &:last-child {
        margin-right: 0; } } }

  &-contacts {
    margin-top: 10px; }

  &-contactsLink {
    display: block;
    color: $color-black;
    text-decoration: none;
    margin-top: 30px; }

  &-hamburger {
    padding: 0;
    height: 22px;
    align-self: center;

    &.is-active {
      .hamburger-inner,
      .hamburger-inner:before,
      .hamburger-inner:after {
        background-color: $color-primary; } }

    &:hover {
      opacity: 1; }

    .hamburger-box {
      width: 30px;
      height: 22px; }

    .hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
      width: 30px;
      height: 5px;
      border-radius: 0;
      background-color: $color-secondary; }

    .hamburger-inner:before {
      top: -8px; }

    .hamburger-inner:after {
      bottom: -8px; } }

  &--menuOpened {
    .Navbar-menu {
      left: 0; } } }

@media(min-width: $screen-sm-min) {
  .Navbar {
    &-logo {
      height: 48px;
      width: 258px; }

    &-indentation {
      height: 88px; }

    &-menu {
      height: calc(100vh - 80px);
      top: 80px; } } }

@media(min-width: $screen-md-min) {
  .Navbar {

    &-menu {
      display: flex;
      align-items: center;
      width: auto;
      height: auto;
      top: auto;
      left: auto;
      position: relative;
      padding-top: 0; }

    &-lang {
      display: block; }

    &-hamburger {
      display: none; }

    &-link {
      margin-right: 25px;
      display: inline-block;
      text-align: left;
      padding: 0;

      &--border {
        max-width: none;
        margin-top: 0;
        padding: 10px 45px; } }

    &-langMob,
    &-contacts {
      display: none; } } }

@media(min-width: $screen-lg-min) {
  .Navbar {

    &-logo {
      height: 57px;
      width: 307px; }

    &-indentation {
      height: 97px; }

    &-link {
      margin-right: 50px; } } }
