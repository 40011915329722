.PortfolioMap {
  margin: 60px 0 25px;
  position: relative;

  svg {
    display: none; }

  &-state {
    fill: #ececec;
    stroke: #fff;
    stroke-width: .5;
    stroke-miterlimit: 10;

    &--highlighted {
      fill: #f39e02;
      cursor: pointer;
      position: relative;

      &:hover {
        fill: #93c01f; } } }

  &-info {
    background-color: $color-white;
    margin-bottom: 60px; } }

@media(min-width: $screen-sm-min) {
  .PortfolioMap {
    margin: 20px 0 25px;

    svg {
      display: block; }

    &-info {
      position: absolute;
      opacity: 0;
      z-index: -1;
      padding: 40px;
      max-width: 250px;
      border-radius: 50% 50% 50% 0;
      box-shadow: 5px 5px 30px 0px rgba(33, 44, 45, .3);
      transition: opacity ease .2s;
      pointer-events: none;
      margin-bottom: 0;
      font-size: 15px;

      &--active {
        opacity: 1;
        z-index: 1; }

      &--spain {
        bottom: 33%;
        left: 28%;
        max-width: 350px; }

      &--portugal {
        bottom: 29%;
        left: 22%; }

      &--france {
        bottom: 53%;
        left: 37%; }

      &--belgium {
        bottom: 67%;
        left: 40%; }

      &--netherlands {
        bottom: 73%;
        left: 41%;
        max-width: 300px; }

      &--denmark {
        bottom: 86%;
        left: 46%; }

      &--germany {
        bottom: 67%;
        left: 47%; }

      &--poland {
        bottom: 72%;
        left: 59%; }

      &--slovakia {
        bottom: 61%;
        left: 59%; }

      &--hungary {
        bottom: 55%;
        left: 59%; }

      &--croatia {
        bottom: 49%;
        left: 54%; }

      &--austria {
        bottom: 57%;
        left: 53%; }

      &--italy {
        bottom: 41%;
        left: 50%;
        max-width: 300px; }

      &--greece {
        bottom: 32%;
        left: 62%; }

      &--turkey {
        bottom: 29%;
        right: 29%;
        border-radius: 50% 50% 0 50%; }

      &--israel {
        bottom: 7%;
        right: 21%;
        border-radius: 50% 50% 0 50%; }

      &--america {
        bottom: 31%;
        left: 3%;
        max-width: 320px; } } } }
