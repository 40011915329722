.Gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 68px;

  &-item {
    width: calc(50% - 6px);
    margin-top: 12px;
    overflow: hidden;

    &:hover {
      .Gallery-inner {
        transform: scale(1.1); } } }

  &-inner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    transition: transform ease .3s; } }

@media(min-width: $screen-md-min) {
  .Gallery {

    &-item {
      width: calc(20% - 12px); } } }
