.Contacts {
  padding: 40px 0;
  background-color: $color-ternary;
  position: relative;
  z-index: 1;
  text-align: center;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 25px 0 25px;
    border-color: $color-ternary transparent transparent transparent;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%); }

  &-item {
    width: 100%;
    color: $color-white;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0; } }

  &-icon {
    padding-left: 31px;
    font-family: "Krub";
    font-weight: 600;
    color: $color-white;
    text-decoration: none;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 20px;

    &:hover {
      text-decoration: underline; }

    &--phone {
      background-image: url('/images/phone.svg'); }

    &--fax {
      background-image: url('/images/fax.svg'); }

    &--mail {
      background-image: url('/images/mail.svg'); } } }

@media(min-width: $screen-md-min) {
  .Contacts {
    padding: 60px 0;
    text-align: left;

    &-item {
      padding: 0 60px;
      width: 33.33%;
      border-right: 1px solid #81a800;
      margin-bottom: 0;

      &:first-child {
        padding-left: 0; }

      &:last-child {
        padding-right: 0;
        border: none; } } } }
