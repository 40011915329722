@font-face {
	font-family: "Krub";
	src: url("/fonts/Krub/Krub-Light.ttf");
	font-style: normal;
	font-weight: 300; }

@font-face {
	font-family: "Krub";
	src: url("/fonts/Krub/Krub-Regular.ttf");
	font-style: normal;
	font-weight: 400; }

@font-face {
	font-family: "Krub";
	src: url("/fonts/Krub/Krub-Medium.ttf");
	font-style: normal;
	font-weight: 500; }

@font-face {
	font-family: "Krub";
	src: url("/fonts/Krub/Krub-Bold.ttf");
	font-style: normal;
	font-weight: 700; }

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/Open_Sans/OpenSans-Light.ttf");
	font-style: normal;
	font-weight: 300; }

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/Open_Sans/OpenSans-Regular.ttf");
	font-style: normal;
	font-weight: 400; }

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/Open_Sans/OpenSans-SemiBold.ttf");
	font-style: normal;
	font-weight: 500; }

@font-face {
	font-family: "Open Sans";
	src: url("/fonts/Open_Sans/OpenSans-Bold.ttf");
	font-style: normal;
	font-weight: 700; }
