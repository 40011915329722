.Button {
  display: inline-block;
  color: $color-white;
  text-decoration: none;
  background-color: $color-primary;
  padding: 20px 80px;
  border-radius: 32px;
  transition: $transition;
  cursor: pointer;
  font-size: 16px;

  &:hover {
    background-color: #93c01f; }

  &:focus {
    outline: none; }

  &--hero {
    position: absolute;
    left: 50%;
    bottom: -32px;
    transform: translateX(-50%);
    min-width: 290px;
    text-align: center; }

  &--secondary {
    background-color: transparent;
    color: $color-black;
    border: 2px solid $color-primary;
    padding: 10px 45px;
    border-radius: 23px;

    &:hover {
      background-color: $color-primary;
      color: $color-white; } }

  &--contact {
    margin-top: 50px; } }

@media(min-width: $screen-sm-min) {
  .Button {

    &--contact {
      margin-top: 100px; } } }
